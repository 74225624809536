<template>
  <div class="list-user">
    <!-- <OutletSelect @change="onSelectOutlet"></OutletSelect> -->
    <KTPortlet title="Danh sách nhân viên">
      <template v-slot:toolbar>
        <router-link :to="{ name: 'user-create' }">
          <i class="flaticon-add mr-2"></i>
          <span>Tạo mới</span>
        </router-link>
      </template>

      <template v-slot:body>
        <!-- Search input -->
        <div class="row">
          <div class="col-6">
            <SearchInput
              pHolder="Tìm kiếm tên NV..."
              @search="onSearch"
            ></SearchInput>
          </div>
          <div class="col-6 search-outlet">
            <OutletSelect
              @change="onSelectOutlet"
              :hidenTitle="true"
            ></OutletSelect>
          </div>
        </div>


        <!-- Table -->
        <div class="mt-4 table-user table-custom">
          <b-table
            :items="users"
            :current-page="currentPage"
            responsive="sm"
            :fields="fields"
            :sticky-header="true"
            head-variant="light"
            striped
            v-if="users.length"
          >
            <template v-slot:cell(name)="data">
              <div
                class="btn-sm"
                v-b-toggle="data.value"
                @click="data.toggleDetails"
              >
                <span class="icon mr-2 icon-arrow"
                  ><i
                    :class="
                      data.detailsShowing ? 'flaticon2-back' : 'flaticon2-down'
                    "
                  >
                  </i>
                </span>
                <span class="data-value">{{ data.value }}</span>
              </div>
            </template>
            <template v-slot:head(status)> Trạng thái</template>
            <template v-slot:cell(status)="status">
              <b-dropdown
                right
                variant="success"
                no-caret
                id="dropdown-right"
                text="Kích hoạt"
                v-if="status.value === 1"
                size="sm"
                class="btn-status"
              >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(status, statusUser.Actived)"
                  >Kích hoạt</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(status, statusUser.NotActived)"
                  >Chưa KH</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                right
                variant="danger"
                no-caret
                id="dropdown-right"
                text="Chưa KH"
                size="sm"
                class="btn-status"
                v-else-if="status.value === 2"
              >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(status, statusUser.Actived)"
                  >Kích hoạt</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(status, statusUser.NotActived)"
                  >Chưa KH</b-dropdown-item
                >
              </b-dropdown>
            </template>
            <template v-slot:cell(role)="data">
              <div>{{ getRole(data.value) }}</div>
            </template>
            <template v-slot:head(_id)="_id">
              <span :id="_id.value"></span>
            </template>
            <template v-slot:cell(_id)="data">
              <div class="text-right edit-user">
                <b-dropdown
                  right
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="b-none"
                >
                  <template v-slot:button-content>
                    <i class="flaticon-more"></i>
                  </template>
                  <b-dropdown-item>
                    <router-link
                      :to="{
                        name: 'user-edit',
                        params: { item: data.item }
                      }"
                    >
                      <div class="d-flex align-items-center">
                        <i class="flaticon-edit-1 mr-2"></i>
                        <span>Chỉnh sửa</span>
                      </div>
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot:row-details="row">
              <b-card class="card-content">
                <p class="card-text">
                  Tên viết tắt:
                  <span class="content">{{ row.item.shortName }}</span>
                </p>
                <p class="card-text">
                  Ngày sinh:
                  <span class="content">{{ formatDate(row.item.dob) }}</span>
                </p>
                <p class="card-text">
                  Địa chỉ: <span class="content">{{ row.item.address }}</span>
                </p>
                <p class="card-text">
                  Số điện thoại:
                  <span class="content">{{ row.item.phoneNumber }}</span>
                </p>
                <p class="card-text">
                  Email: <span class="content">{{ row.item.email }}</span>
                </p>
                <p class="card-text">
                  Lương cố định:
                  <span class="content">{{ row.item.salary }}</span>
                </p>
                <p class="card-text">
                  Phần trăm hoa hồng:
                  <span class="content">{{ row.item.commissionPercent }}</span>
                </p>
              </b-card>
            </template>
          </b-table>
          <div v-else class="d-flex justify-content-center">
            <section v-if="loading" class="loading align-items-center">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </section>
            <div class="loading align-items-center" v-else>
              <div class="text-center">
                <h1><i class="flaticon2-open-box"></i></h1>
                <h5>Không tìm thấy dữ liệu thích hợp</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-center"
          v-if="totalItem > itemPage"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItem"
            :per-page="itemPage"
            id="promo-table"
          ></b-pagination>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/report/outlet.scss";
@import "./User.scss";
.loading {
  height: calc(100vh - 505px);
}
</style>

<script>
import moment from "moment";
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import SearchInput from "@/components/SearchInput.vue";
import { Status, UserRole } from "./../../constans";
import Swal from "sweetalert2";
import { getUsers } from "@/api/user";
import Loader from "@/common/loader";

export default {
  name: "UserList",
  components: {
    KTPortlet,
    OutletSelect,
    SearchInput
  },
  data() {
    return {
      currentPage: 1,
      outletIdCurrent: "",
      statusUser: Status,
      dataUsersUpdate: [],
      totalItem: 0,
      itemPage: 10,
      loading: false,
      searchText: "",
      users: [],
    };
  },
  watch: {
    currentPage(val) {
      this.getDataUsers();
    }
  },

  methods: {
    async onSelectOutlet(event) {
      this.outletIdCurrent = event;
      Loader.fire();
      await this.getDataUsers();
      Loader.close();
    },

    getDataUsers() {
      this.users = [];
      this.loading = true;
      return getUsers({
        page: this.currentPage,
        text: this.searchText,
        outletId: this.outletIdCurrent
      }).then(res => {
          this.users = res.data.users;
          this.totalItem = res.data.total;
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false;
        });
    },

    onSearch(event) {
      this.searchText = event;
      this.getDataUsers();
    },

    changeActived(data, actived) {
      Loader.fire();
      const query = {
        id: data.item._id,
        bodyParams: {
          status: actived
        }
      };
      this.$store
        .dispatch("updateUser", query)
        .then(() => {
          this.dataUsersUpdate = this.users;
          const indexUser = this.users.findIndex(item => {
            return item._id === data.item._id;
          });
          if (indexUser !== -1) {
            this.dataUsersUpdate[indexUser].status = query.bodyParams.status;
          }
        })
        .finally(Loader.close);
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getRole(role) {
      switch (role) {
        case UserRole.Staff:
          return "Nhân viên";

        case UserRole.Cashier:
          return "Thu ngân";

        case UserRole.OutletManager:
          return "Quản lý cửa hàng";

        case UserRole.OutletBoss:
          return "Chủ tiệm";

        case UserRole.CEO:
          return "CEO";
        default:
          return "";
      }
    }
  },
  mounted() {
    this.$store.dispatch("titleBreadcrumb", "Quản lý người dùng và quyền");
  },
  computed: {
    fields() {
      return  [
        {
          key: "name",
          label: "Tên nhân viên"
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "role",
          label: "Quyền"
        },
        {
          key: "_id",
          label: ""
        }
      ]
    }
  }
};
</script>
